var FORMCONTROLLER_I18N;

(function()
{
	'use strict';
	angular.module('forms', [ 'ngSanitize' ]).controller('FormController', ['$scope', '$mdDialog', '$http', '$attrs', '$element', '$util', '$q', '$timeout', '$parse', function($scope, $mdDialog, $http, $attrs, $element, $util, $q, $timeout, $parse)
	{
		/* Formatters */
		$scope.formatDateTime = function(d)
		{
			var format																= moment.localeData().longDateFormat('L') + ' ' + moment.localeData().longDateFormat('LT');

			return d ? moment(d).format(format) : '';
		};

		$scope.formatDate = function(d)
		{
			var format																= moment.localeData().longDateFormat('L');

			return d ? moment(d).format(format) : '';
		};

		/* Parsers */
		$scope.parseDate = function(a)
		{
			return a ? a.toMoment().toDate() : moment.invalid();
		};

		$scope.parseObjectArray = function(arr, key, sep)
		{
			if (sep === undefined)													{ sep = ', '; }

			if (arr)
			{
				var ret																= '';
				var tmp, m;

				for (var i = 0; i < arr.length; i = i + 1)
				{
					if (i > 0)														{ ret = ret + sep; }

					if (key.indexOf('[') > -1)
					{
						tmp															= key;
						m															= tmp.match(/\[(.*?)\]/);

						while ((m = tmp.match(/\[(.*?)\]/)) != null)				{ tmp = tmp.replace(/\[(.*?)\]/, arr[i][m[1]]); }

						ret															= ret + tmp;
					}
					else															{ ret = ret + arr[i][key]; }
				}

				return ret;
			}
			else																	{ return ''; }
		}

		$scope.parseStringToArray = function(str, sep)
		{
			if (sep === undefined)													{ sep = ', '; }

			if (str)																{ return str.split(sep); }
			else																	{ return []; }
		};

		/* Utilities */
		$scope.toggle = function(array, value)
		{
			var i																	= array.indexOf(value);
			if (i == -1)															{ array.push(value); }
			else																	{ array.splice(i, 1); }
		};

		$scope.toggleWithIntermediate = function(a)
		{
			var v																	= $parse(a);
			var value																= v($scope);

			if		(value === null || value === undefined || value === 'null')		{ value = true; }
			else if (value === true)												{ value = false; }
			else if (value === false)												{ value = 'null'; }

			$parse(a).assign($scope, value);
		};

		$scope.mapToArray = function(source, target)
		{
			var initialValue														= $parse(target)($scope);

			if (initialValue)
			{
				var d																= {};
				for (var i = 0; i < initialValue; i = i + 1)						{ d[initialValue[i]] = true; }
				$parse(source).setter($scope, d);
			}

			$scope.$watchCollection(source, function(newValue)
			{
				var v																= $parse(target);
				var a;

				if (newValue)														{ a = $.map(newValue, function(value, key){ return value == true ? key : undefined }); }
				else																{ a = []; }

				v.assign($scope, a);
			});
		};

		var DialogController = function($scope, $mdDialog)
		{
			$scope.ok = function()
			{
				$mdDialog.hide();
			};
		};

		DialogController.$inject													= ["$scope", "$mdDialog" ];

		$scope.showInfo = function(ev, url)
		{
			$mdDialog.show(
			{
				controller: DialogController,
				templateUrl: url,
				parent: angular.element(document.body),
				targetEvent: ev,
				clickOutsideToClose: true
			});
		};

		/* Default formController */
		var controller																= this;
		controller.element															= $element;
		var submitted																= false;

		controller.formData															= {};
		controller.previousURL														= null;
		controller.id																= $attrs.id;
		controller.name																= 'formController' + $attrs.count;
		controller.entryId															= null;

		controller.afterSubmit = function(response, hasError)
		{
			$scope.$emit('afterSubmit', controller, response, hasError);

			if		(response.data.newURL)											{ submitted = true; window.location.replace(response.data.newURL); }
			else if (response.data.goBack)											{ submitted = true; window.history.go(-1); }
			else if (!hasError)														{ submitted = true; window.location.reload(false); }
		};

		controller.submit = function(act)
		{
			var form																= $scope['formController' + $attrs.count + '_form'];

			// Set the error state of the form. I.e., check required fields, ensure that invalid fields are marked as invalid.
			form.$commitViewValue();
			form.$setSubmitted();

			$scope.$emit('beforeSubmit', controller);

			$http({
				url: '/lib/atsc/forms/' + controller.id + '/' + (act ? act : 'submit') + (controller.entryId ? '/' + controller.entryId : ''),
				method: 'post',
				data: JSON.stringify(controller.formData)
			}).then(function(response)
			{
				if (response && response.status == 200 && response.data)
				{
					if (!controller.entryId)										{ controller.entryId = response.headers('X-Entry-Id'); }

					var hasError													= false;
					var cont = function()
					{
						submitted													= true;

						// Reset the form state if it was submitted successfully.
						if (!hasError)												{ form.$setPristine(); }

						controller.afterSubmit(response, hasError);
					};

					if (response.data.error)
					{
						hasError													= true;
						$mdDialog.show(
							$mdDialog.alert()
								.clickOutsideToClose(true)
								.title(response.data.error.title || translate('keyword/error'))
								.htmlContent(response.data.error.message)
								.ok(translate('keyword/ok'))
						).then(cont, cont);
					}
					else if (response.data.message)
					{
						$mdDialog.show(
							$mdDialog.alert()
								.clickOutsideToClose(true)
								.title(response.data.message.title || translate('message'))
								.htmlContent(response.data.message.message)
								.ok(translate('keyword/ok'))
						).then(cont, cont);
					}
					else
					{
						cont();
					}
				}
				else
				{
					$mdDialog.show(
						$mdDialog.alert()
							.clickOutsideToClose(true)
							.title(translate('server/errortitle'))
							.textContent(translate('server/errortext') + '!')
							.ok(translate('keyword/ok'))
					);
				}
			}, function(e)
			{
				$mdDialog.show(
					$mdDialog.alert()
						.clickOutsideToClose(true)
						.title(translate('server/errortitle'))
						.textContent(translate('server/errortext') + '!')
						.ok(translate('keyword/ok'))
				);
			});
		};

		controller.close = function()
		{
			var quit = function()
			{
				if ($scope.previousURL)												{ window.location.replace($scope.previousURL); }
				else																{ history.back(); }
			};

			if (controller.isDirty())
			{
				$mdDialog.show(
					$mdDialog.confirm()
						.title(translate('keyword/close') + '?')
						.textContent(translate('keyword/close_without_save'))
						.ok(translate('keyword/ok'))
						.cancel(translate('keyword/cancel'))
				).then(function()
				{
					submitted														= true;

					quit();
				});
			}
			else																	{ quit(); }
		};

		var initialData;

		controller.isDirty = function()
		{
			if (initialData)														{ return !angular.equals(controller.formData, initialData); }
			else																	{ return false; }
		};

		$timeout(function()
		{
			var cont = function()
			{
				// $scope.pendingLookupDirectives is set by atsc-asset-datatables if there are lookup fields on the current form.
				// It is assumed that this variable is not set by other scripts, making it safe to check here.
				if ($scope.pendingLookupDirectives > 0)								{ $timeout(cont, 10); }
				else
				{
					initialData														= angular.merge({}, controller.formData);

					$scope.$emit('form.specificInit', controller);

					$(window).on('beforeunload', function(e)
					{
						if (controller /*&& controller.form*/ && controller.isDirty() && !submitted)
						{
							e.preventDefault();

							return translate('keyword/close_without_save');
						}
					});
				}
			};

			cont();
		}, 0);
	}]);
})();

(function(){
	'use strict';

	angular.module('forms')
	.directive('filelist', [ '$compile', '$mdDialog', function($compile, $mdDialog)
	{
		var translate = function(txt)
		{
			try
			{
				if (FORMCONTROLLER_I18N)																{ return FORMCONTROLLER_I18N[txt]; }
				else																					{ return txt; }
			}
			catch (ex)																					{ return txt; }
		};

		return {
			restrict: 'E',
			scope:
			{
				model: '=?'
			},
			compile: function(element, attrs, ctrls)
			{
				var multiple																			= attrs.multiple;
				var initialValue																		= $(element).text();
				var $wrapper																			= $('<div class="filelist"></div>');

				if (!attrs.readonly)
				{
					var $input																			= $('<input type="file" style="opacity: 0; width: 160px; height: 35px; position: relative; z-index: 2;" />');
					var $fakeInput																		= $('<button type="button" style="width: 160px; height: 35px; position: absolute;"></button>').text(translate('upload'));

					$input.attr('accept', attrs.accept);
					$input.attr('maxlength', attrs.maxlength);

					$wrapper.append($fakeInput);
					$wrapper.append($input);
				}

				if (multiple)
				{
					var $list																			= $('<ul></ul>');

					$wrapper.append($list);
				}
				else
				{
					var $existingFileWrapper															= $('<div style="display: inline-block;" data-filelist-id="existing-file"' + (attrs.readonly ? '' : ' ng-show="model"') + '></div>');

					$existingFileWrapper.append($('<span></span>').html('&nbsp;&nbsp;'));
					$existingFileWrapper.append($('<span></span>').text(translate('current_file') + ': '));
					$existingFileWrapper.append($('<span></span>').html('&nbsp;&nbsp;'));
					$existingFileWrapper.append($('<span data-filelist-id="existing-file-name">{{model.name || \'(' + translate('none').toLowerCase() + ')\'}}</span>'));
					$existingFileWrapper.append($('<span></span>').html('&nbsp;&nbsp;'));
					$existingFileWrapper.append($('<a ng-click="remove();" ng-show="model"><i class="fa fa-trash-o"></i></a>'));

					$wrapper.append($existingFileWrapper);
				}

				element.replaceWith($wrapper[0]);

				return {
					pre: function(scope, element, attrs, ctrls)
					{
						if (initialValue)
						{
							var model																	= null;

							try																			{ model = JSON.parse(initialValue); }
							catch (ex)																	{ /* no problem */ }

							if (!multiple && model)														{ model = model[0]; }

							scope.model																	= model;
						}
					},
					post: function postLink(scope, element, attrs, ctrls)
					{
						element																			= $compile(element)(scope);

						$wrapper																		= $(element);
						$input																			= $wrapper.find('> input[type=file]').first();
						$list																			= $wrapper.find('> ul').first();

						scope.remove = function(index)
						{
							if (multiple)
							{
								scope.model.splice(index, 1);
							}
							else
							{
								scope.model = null;
							}
						};

						scope.$watchCollection('model', function(newValues)
						{
							$list.empty();

							if (newValues && newValues.length > 0)
							{
								var fileIcon;

								for (var i = 0; i < newValues.length; i = i + 1)
								{
									if (newValues[i].type.startsWith('image/'))
									{
										fileIcon														= 'fa-file-image-o';
									}
									else if (newValues[i].type.startsWith('application/pdf'))
									{
										fileIcon														= 'fa-file-pdf-o';
									}
									else if (newValues[i].type.startsWith('application/msword') || newValues[i].type.startsWith('application/vnd.openxmlformats-officedocument') || newValues[i].type.startsWith('application/vnd.ms-excel') || newValues[i].type.startsWith('application/vnd.oasis.opendocument'))
									{
										fileIcon														= 'fa-file-text-o';
									}
									else
									{
										fileIcon														= 'fa-file-o';
									}

									$list.append($compile($('<li></li>').append($('<a ng-click="remove(' + i + ');"><i class="fa fa-trash-o"></i></a>')).append('<span>&nbsp;&nbsp;&nbsp;</span>').append($('<i class="fa"></i>').addClass(fileIcon)).append('<span>&nbsp;&nbsp;&nbsp;</span>').append($('<span></span>').text(newValues[i].name))[0])(scope));
								}
							}
						});

						$input.on('change', function()
						{
							if ($input[0].files && $input[0].files.length)
							{
								var file																= $input[0].files[0];

								var accept																= ('' + $input.attr('accept')).split(/,/g);
								var ok																	= false;
								var i;

								for (i = 0; i < accept.length; i = i + 1)
								{
									if (accept[i].endsWith('/*'))
									{
										if (file.type.startsWith(accept[i].backwardsLeft('/*') + '/'))	{ ok = true; break; }
									}
									else
									{
										if (file.type == accept[i])										{ ok = true; break; }
									}
								}

								if (ok)
								{
									var maxLength														= parseFloat($input.attr('maxlength'));

									if (file.size > maxLength)
									{
										$mdDialog.show(
											$mdDialog.alert()
												.clickOutsideToClose(true)
												.title(translate('error'))
												.htmlContent(translate('file_size_limit'))
												.ok(translate('ok'))
										);

										$input.val('');
									}
									else
									{
										var reader														= new FileReader();

										reader.onload = function (loadEvent)
										{
											scope.$apply(function()
											{
												var fileObject											= { 'name': file.name, 'size': file.size, 'type': file.type, 'encodedBytes': loadEvent.target.result.right(';base64,') };

												if (multiple)
												{
													if (!scope.model)									{ scope.model = []; }

													scope.model.push(fileObject);
												}
												else
												{
													scope.model											= fileObject;
												}

												$input.val('');
											});
										};

										reader.readAsDataURL(file);
									}
								}
								else
								{
									$mdDialog.show(
										$mdDialog.alert()
											.clickOutsideToClose(true)
											.title(translate('error'))
											.htmlContent(translate('file_type_limit'))
											.ok(translate('ok'))
									);

									$input.val('');
								}
							}
						});
					}
				};
			}
		};
	}]);
})();

FORMCONTROLLER_I18N = {
	'connection_failed': 'Verbinding mislukt',
	'could_not_connect': 'Kon geen verbinding maken met de server. Controleer de netwerkverbinding en gelieve opnieuw te proberen.',
	'ok': 'OK',
	'cancel': 'Annuleren',
	'error': 'Fout',
	'close': 'Sluiten',
	'close_without_save': 'Uw wijzigingen gaan verloren. Weet u zeker dat u wilt sluiten?',
	'file_size_limit': 'Het aangeleverde bestand is te groot. Upload afgebroken.',
	'file_type_limit': 'Het aangeleverde bestand is niet geldig. Upload afgebroken.',
	'message': 'Melding',
	'none': 'Geen',
	'upload': 'Bestand uploaden',
	'current_file': 'Huidig bestand'
}

